import { useState, useEffect } from "react";
import timer from "../data/timer.json";
import { useTranslation } from "react-i18next";

const Countdown = () => {
  const { t } = useTranslation();

  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    updateCountdown(timer.targetDate);
  }, []);

  const updateCountdown = (targetDate) => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance <= 0) {
        clearInterval(intervalId);
        setCountdown({
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24))
          .toString()
          .padStart(2, "0");
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)
          .toString()
          .padStart(2, "0");
        setCountdown({ days, hours, minutes, seconds });
      }
    }, 1000);
  };
  return (
    <>
      <ul className="d-flex mx-auto time_box justify-content-center gap-4 gap-sm-2 gap-md-4 text-center list-unstyled mb-0 custom_border">
        <li className="fw-bold ff_Montserrat text_light_black fs_2xl lh_normal">
          <span className="d-block fs_md fw-normal">{t("days")}</span>
          {countdown.days}
        </li>
        <li className="fw-bold ff_Montserrat text_light_black fs_2xl lh_normal">
          <span className="d-block fs_md fw-normal">{t("hours")}</span>
          {countdown.hours}
        </li>
        <li className="fw-bold ff_Montserrat text_light_black fs_2xl lh_normal">
          <span className="d-block fs_md fw-normal">{t("minutes")}</span>
          {countdown.minutes}
        </li>
        <li className="fw-bold ff_Montserrat text_light_black fs_2xl lh_normal">
          <span className="d-block fs_md fw-normal">{t("seconds")}</span>
          {countdown.seconds}
        </li>
      </ul>
    </>
  );
};

export default Countdown;
