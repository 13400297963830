import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../switcher.css"; // assuming you have a CSS file for styling

import spain from "../assets/images/flags/spain.png";
import america from "../assets/images/flags/america.png";
import china from "../assets/images/flags/china.png";
import turkey from "../assets/images/flags/turkey.png";
import korea from "../assets/images/flags/korea.png";
import japan from "../assets/images/flags/japan.png";
import russia from "../assets/images/flags/russia.png";
import portugual from "../assets/images/flags/portugual.png";
import arabic from "../assets/images/flags/arabic.png";
import italy from "../assets/images/flags/italy.png";
import german from "../assets/images/flags/germany.png";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Default language is English

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
    setShowDropdown(false); // Close dropdown after language selection
  };

  // Map language codes to their respective flag images
  const flagImages = {
    en: america,
    es: spain,
    tr: turkey,
    ja: japan,
    ko: korea,
    ru: russia,
    zh: china,
    ar: arabic,
    it: italy,
    pt: portugual,
    de: german,
    // Add other language codes and flag images as needed
  };

  return (
    <div className="selector">
      <a
        onClick={() => setShowDropdown(!showDropdown)}
        className="language-current"
      >
        <img src={flagImages[currentLanguage]} alt="LAN" />
      </a>
      {showDropdown && (
        <div className="language-selector">
          <a onClick={() => changeLanguage("en")}>
            {" "}
            <img src={america} alt="Flag" /> English - en
          </a>
          <a onClick={() => changeLanguage("es")}>
            {" "}
            <img src={spain} alt="Flag" /> Spanish - es
          </a>
          <a onClick={() => changeLanguage("tr")}>
            {" "}
            <img src={turkey} alt="Flag" /> Turkish - tr
          </a>
          <a onClick={() => changeLanguage("ja")}>
            {" "}
            <img src={japan} alt="Flag" /> Japanese - ja
          </a>
          <a onClick={() => changeLanguage("ko")}>
            {" "}
            <img src={korea} alt="Flag" /> Korean - ko
          </a>
          <a onClick={() => changeLanguage("ru")}>
            {" "}
            <img src={russia} alt="Flag" /> Russian - ru
          </a>
          <a onClick={() => changeLanguage("zh")}>
            {" "}
            <img src={china} alt="Flag" /> Chinese - zh
          </a>
          <a onClick={() => changeLanguage("ar")}>
            {" "}
            <img src={arabic} alt="Flag" /> Arabic - ar
          </a>
          <a onClick={() => changeLanguage("it")}>
            {" "}
            <img src={italy} alt="Flag" /> Italian - it
          </a>
          <a onClick={() => changeLanguage("pt")}>
            {" "}
            <img src={portugual} alt="Flag" /> Portuguese - pt
          </a>
          <a onClick={() => changeLanguage("de")}>
            {" "}
            <img src={german} alt="Flag" /> German - de
          </a>
          {/* Add buttons for other languages */}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
