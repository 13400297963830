import React from "react";
import egg from "../assets/images/svg/egg-img.svg";
import hen from "../assets/images/webp/hen.webp";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import ConnectWalletBtn from "./ConnectWalletBtn";

import { useEffect, useState } from "react";
import {
  useChainId,
  useBalance,
  useAccount,
  useWriteContract,
  useConfig,
  useReadContract,
} from "wagmi";
import { waitForTransactionReceipt } from "@wagmi/core";
import { parseEther, formatEther } from "viem";
import presaleABI from "../data/presaleABI.json";
import Countdown from "./Countdown";

import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from "@rainbow-me/rainbowkit";
import truncateEthAddress from "truncate-eth-address";

const contractAddress = "0x68556732d6712856EBc0054752052FA456d715Ce"; // updated
const rate = "1375000";
const tokenAddress = "0xA7c1c1c91b28f3d1B84e837Ac2B430EAb7CF8243";
const bnbPrice = "550";
const targetAmount = 2240000;

const Hero = () => {
  const { t } = useTranslation();

  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");

  const config = useConfig();

  const { address } = useAccount();
  const chainId = useChainId();
  const { data: balanceData, isFetched } = useBalance({ address: address });

  const [balance, setBalance] = useState(0);

  const [exchangeAmount, setExchangeAmount] = useState("");
  const [returnAmount, setReturnAmount] = useState("");
  const [validExchange, setValidExchange] = useState(false);
  const [loader, setLoader] = useState(false);
  const [inlineInfo, setInlineInfo] = useState("");

  const [raisedWei, setRaisedWei] = useState(0);
  const [boughtToken, setBoughtToken] = useState(0);
  const [progress, setProgress] = useState(0);

  const { data: raisedAmount } = useReadContract({
    address: contractAddress,
    abi: presaleABI,
    functionName: "_weiRaised",
  });

  const { data: bought } = useReadContract({
    address: contractAddress,
    abi: presaleABI,
    functionName: "holders",
    account: address,
    args: [address],
  });

  useEffect(() => {
    if (raisedAmount) {
      const raised = ((Number(raisedAmount) / 10 ** 18) * bnbPrice).toFixed(2);
      setRaisedWei(raised);
      const percent = (Number(raised) / Number(targetAmount)) * 100;
      setProgress(percent.toFixed(2));
    }
  }, [raisedAmount]);

  useEffect(() => {
    if (bought) {
      setBoughtToken((Number(bought) / 10 ** 18).toFixed(2));
    }
  }, [bought]);

  const handleInput = (event) => {
    const value = event.target.value;
    setExchangeAmount(value);
    validateInput(value);
  };

  const validateInput = (value) => {
    setInlineInfo("");
    setValidExchange(false);
    setReturnAmount("");
    const returnToken = (Number(rate) * Number(value)).toFixed(2);
    setReturnAmount(returnToken);
    const actualBalance = formatEther(balance);
    if (Number(value) > Number(actualBalance)) {
      setInlineInfo("Not Enough Balanace");
      setValidExchange(false);
    } else if (Number(value) > 0.1) {
      setValidExchange(true);
    }
  };

  useEffect(() => {
    if (isFetched) {
      const convertedBalance = balanceData.value.toString();
      setBalance(convertedBalance);
    }
  }, [balanceData]);

  const showError = async (text, time) => {
    setError(text);
    await new Promise((r) => setTimeout(r, time));
    setError("");
  };
  const showInfo = async (text, time) => {
    setInfo(text);
    await new Promise((r) => setTimeout(r, time));
    setInfo("");
  };

  const { writeContractAsync } = useWriteContract();
  const buyTokens = async () => {
    setLoader(true);
    try {
      const trx = await writeContractAsync({
        address: contractAddress,
        abi: presaleABI,
        functionName: "buyTokens",
        value: parseEther(exchangeAmount.toString()),
        account: address,
      });

      const hash = await waitForTransactionReceipt(config, {
        hash: trx,
        confirmations: 4,
      });
      setLoader(false);
      alert("Token Purchased");
      // showInfo("Transaction Processed", 4000);
    } catch (error) {
      // showError("Transaction Failed", 4000);
      console.log(error);
      setLoader(false);
      alert("Transaction Failed");
    }
    setLoader(false);
  };
  return (
    <div className="container custom_container mx-auto">
      <Header />

      <div className="d-flex gap-2 align-items-center align-items-sm-start flex-column-reverse flex-sm-row justify-content-between">
        <div className="col-sm-6 col-xl-auto pt-sm-2">
          <div className="presale_content_box w-100 text-center">
            <h4 className="ff_Montserrat text_light_black fs_xl fw-semibold lh_121 mb-2 mb-xl-4">
              {t("presaleHead")}
            </h4>
            <Countdown />
            <p className="text_light_black ff_Montserrat fs_md my-1">
              USDT {t("raised")}: ${raisedWei} / $2,240,000
            </p>
            {/* <input
              className="bg_yellow custom_border w-100 h_22 px-2 fs_xs ff_Montserrat fw-semibold"
              type="number"
            /> */}
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <p className="text_light_black ff_Montserrat fs_md mt-2 mt-xl-3 mb-0">
              {t("purchased")} $EGGO = {boughtToken}
            </p>
            <p className="text_light_black ff_Montserrat fs_md my-1">
              {t("one")} $EGGO = $0.0004
            </p>
            <p className="text_light_black ff_Montserrat fs_md mt-2 mt-xl-3 mb-0">
              {t("minimum")}: 0.1 BNB
            </p>
            <div className="d-flex justify-content-between py-2 py-xl-4 my-xl-3">
              <div className="col-6 col-sm-auto">
                <div className="content_box pe-2 pe-sm-0">
                  <h3 className="fw-bold text_light_black fs_md ff_Montserrat">
                    {t("pay")}
                  </h3>
                  <input
                    value={exchangeAmount}
                    onChange={handleInput}
                    className="bg_yellow custom_input_style text_light_black custom_border fs_md ff_Montserrat fw-semibold w-100"
                    type="number"
                  />
                </div>
              </div>
              <div className="col-6 col-sm-auto">
                <div className="content_box ps-2 ps-sm-0">
                  <h3 className="fw-bold text_light_black fs_md ff_Montserrat">
                    {t("get")}
                  </h3>
                  <input
                    value={returnAmount}
                    readOnly
                    className="bg_yellow custom_input_style text_light_black custom_border fs_md ff_Montserrat fw-semibold w-100"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between ">
              {/* <div className="col-6 col-sm-auto pe-2 pe-sm-0">
                {address ? (
                  <button
                    onClick={openAccountModal}
                    className="buy_btn ff_Montserrat fw-bold fs_base"
                  >
                    {truncateEthAddress(address)}
                  </button>
                ) : (
                  <button
                    onClick={openConnectModal}
                    className="buy_btn ff_Montserrat fw-bold fs_base"
                  >
                    Connect Wallet
                  </button>
                )}
              </div> */}
              {/* <div className="col-6 col-sm-auto ps-2 ps-sm-0">
                {validExchange && !loader && (
                  <button
                    onClick={buyTokens}
                    className="buy_btn ff_Montserrat fw-bold fs_base"
                  >
                    BUY WITH BNB
                  </button>
                )}

                {!validExchange && !loader && (
                  <button className="buy_btn_dull ff_Montserrat fw-bold fs_base">
                    BUY WITH BNB
                  </button>
                )}
                {loader && (
                  <button className="buy_btn_dull ff_Montserrat fw-bold fs_base">
                    Loading...
                  </button>
                )}
              </div> */}
            </div>
            <p className="text_light_black ff_Montserrat fs_base mb-0 pt-2 pt-md-4">
              {t("next")} $EGGO = 0.0006
            </p>
          </div>
        </div>
        <div className="col-9 col-sm-auto">
          <div className="position-relative ms-xl-5 ms-sm-4 group_egg_hover">
            <img
              className="w-100 hen_egg_img position-relative z-2"
              src={egg}
              alt="egg-img"
              style={{ zIndex: -1 }}
            />
            <img
              className="position-absolute only_hen"
              src={hen}
              alt="hen-img"
            />
            <div className="position-absolute z-3 top-50 start-50 translate-middle ff_Victory d-flex flex-column align-items-center gap-2">
              <p className="text-center fs_xBase text-black mb-0 para_leading">
                {t("launchDate")}
              </p>
              <h3 className="text-black fs_5xl text-center mb-0 egg_heading">
                {t("saleHead")}
              </h3>
              <p className="text-black fs_base text-center mb-0 para_leading">
                {t("saleText")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="col-sm-6 col-md-8 col-xl-5 px-md-3  pt-4 pt-md-0">
          <h2 className="text-uppercase text-black ff_Victory text-black mb-1 fs_4xl">
            aureggo
          </h2>
          <p className="ff_Montserrat text-black fs_sm mb-0 pe-md-2">
            <span className="fw-semibold fs_custom para_leading text-black">
              {t("introHead")}:
            </span>{" "}
            {t("intro")}
          </p>
        </div>
      </div>
      <div className="row custom_max_width mx-auto pt-4 pt-md-5 ps-xl-5">
        <div className="col-6 ps-0">
          <div>
            <h2 className="text-uppercase ff_Victory text-black mb-1 fs_4xl">
              {t("tokenomics")}
            </h2>
            <p className="fw-semibold fs_base  ff_Montserrat text-black mb-0">
              {t("supply")}
            </p>
            <p className="fs_sm  mb-0 ff_Montserrat text-black para_leading text-black mt_2">
              <span className="fw-semibold fs_custom">{t("presaleBold")}:</span>
              {t("presale")}
            </p>
            <p className="ff_Montserrat text-black fs_sm  mb-0 para_leading text-black mt_2">
              {t("supportBold")}
            </p>
            <p className="ff_Montserrat text-black fs_sm  mb-0 para_leading text-black pe-3 pe-sm-0 mt_2">
              <span className="fw-semibold fs_custom">{t("periodBol")}:</span>{" "}
              {t("period")}
            </p>
          </div>
        </div>
        <div className="col-6">
          <h2 className="text-uppercase ff_Victory text-black mb-2  mb-sm-1 fs_4xl">
            {t("howHead")}
          </h2>
          <p className="ff_Montserrat text-black fs_sm  mb-0 para_leading text-black">
            <span className="fw-semibold fs_custom">{t("clickBol")}:</span>{" "}
            {t("click")}
          </p>
          <p className="ff_Montserrat text-black fs_sm  mb-0 para_leading text-black mt_2">
            <span className="fw-semibold fs_custom">{t("buyBold")}:</span>{" "}
            {t("buy")}
          </p>
        </div>
      </div>
      <div className="row custom_max_width mx-auto pt-3 ps-xl-5">
        <div className="col-6 ps-0">
          <h2 className="text-uppercase ff_Victory text-black my-2 my-sm-1 fs_4xl">
            {t("launchHead")}
          </h2>
          <p className="ff_Montserrat text-black fs_sm  mb-0 para_leading text-black">
            {t("launch")}
          </p>
        </div>
        <div className="col-6">
          <h2 className="text-uppercase ff_Victory text-black mt-2 my-sm-1 fs_4xl">
            {t("faqHead")}
          </h2>
          <p className="ff_Montserrat text-black fs_sm   mb-1 para_leading text-black">
            <span className="fw-semibold">Q:</span> {t("faq1")} <br />
            {t("faqa1")}
          </p>
          <p className="ff_Montserrat text-black fs_sm   mb-1 para_leading text-black">
            <span className="fw-semibold">Q:</span> {t("faq2")} <br />
            {t("faqa2")}
          </p>
        </div>
      </div>
      <p className="text-center pt-4 pt-md-5 mb-4 ff_Montserrat text-black fs_xsm para_leading text-black">
        <a
          className="text-center ff_Victory text-black fs_xl"
          href="/Audit.pdf"
          target="_blank"
        >
          {t("audit")}
        </a>
      </p>
      <p className="text-center pt-4 pt-md-5 mb-4 ff_Montserrat text-black fs_xsm para_leading text-black">
        <span className="fw-semibold">{t("quick")}</span> {t("quickText")}
      </p>
    </div>
  );
};

export default Hero;
