// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
import translationTR from "./locales/tr/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationKO from "./locales/ko/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationZH from "./locales/zh/translation.json";
import translationAR from "./locales/ar/translation.json";
import translationIT from "./locales/it/translation.json";
import translationPT from "./locales/pt/translation.json";
import translationDE from "./locales/de/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  tr: {
    translation: translationTR,
  },
  ja: {
    translation: translationJA,
  },
  ko: {
    translation: translationKO,
  },
  ru: {
    translation: translationRU,
  },
  zh: {
    translation: translationZH,
  },
  ar: {
    translation: translationAR,
  },
  it: {
    translation: translationIT,
  },
  pt: {
    translation: translationPT,
  },
  de: {
    translation: translationDE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en", // fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
