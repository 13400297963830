import React from "react";
import egg from "../assets/images/svg/nav-egg.svg";
import textAureggo from "../assets/images/svg/text-aureggo.svg";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
const Header = () => {
  const { t } = useTranslation();

  return (
    <header className="position-relative ">
      <div className="position-relative z-1">
        <div className="d-flex justify-content-between align-items-center pt-sm-3 pt-2 pt-lg-3 mt-lg-1 ">
          <img
            className="cursor_pointer page_logo"
            height={80}
            width={80}
            src={egg}
            alt="egg"
          />
          <div className="d-flex align-items-center gap-1 nav_whitepaper">
            <a
              href="/Whitepaper.pdf"
              target="_blank"
              className="ff_Victory cursor_pointer fs_4xl text-black text-decoration-none"
            >
              {t("whitepaper")}
            </a>
            <a
              target="_blank"
              className="ff_Victory fs_4xl text-black text-decoration-none ms-lg-4 ms-sm-3 ms-2"
              href="https://twitter.com/Aureggo"
            >
              x
            </a>
            <a
              target="_blank"
              className="ff_Victory fs_4xl text-black text-decoration-none ms-lg-4 ms-sm-3 ms-2"
              href="https://t.me/aureggo"
            >
              TG
            </a>
            <LanguageSwitcher />
          </div>
        </div>
        <div className="pt-2 pt-sm-3">
          <img className="w-100" src={textAureggo} alt="text-aureggo" />
        </div>
        <p className="ff_Victory fs_lg mb-2 text-black  pt-2 pt-sm-3 mt-1 mb-sm-3 mb-xl-2 text-end ps-lg-4">
          {t("tagline")}
        </p>
      </div>
    </header>
  );
};

export default Header;
