import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Hero from "./components/Hero";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
function App() {
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <div className="d-flex  flex-column justify-content-between align-items-center w-100 ">
          <div className="w-100 page_bg">
            <Hero />
          </div>
        </div>
      </I18nextProvider>
    </>
  );
}

export default App;
